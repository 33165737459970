<template>
  <div class="con">
    <div class="top_box">
      <div class="top_nei_box">
        <div class="title">
          {{ details.xq.job_name }}
        </div>
        <div class="flex_box">
          <div class="top_two_box">
            <span class="yu_fen">{{ details.xq.salary }}k</span>
            <el-divider direction="vertical"></el-divider>
            <span>{{ details.xq.rens.name }}</span>
            <el-divider direction="vertical"></el-divider>
            <span v-if="details.xq.education != null">{{
              details.xq.education.name
            }}</span>
            <el-divider direction="vertical"></el-divider>
            <span v-if="details.xq.degree != null">{{ details.xq.degree.name }}</span>
          </div>
          <div class="button">立即投递</div>
        </div>
      </div>
    </div>
    <div class="one_box">
      <div class="zhi_one">
        <el-divider content-position="left">职位描述</el-divider>
        <div class="two_box">
          <div class="tab"><span>专业职业职称</span></div>
          <div class="title"><span>{{details.xq.job_name}}</span></div>
        </div>
        <div class="two_box">
          <div class="tab"><span>职位职责</span></div>
          <div class="title">
            <span>{{ details.xq.duty }}</span>
          </div>
        </div>
        <div class="two_box">
          <div class="tab"><span>职位要求</span></div>
          <div class="title">
            <span>{{ details.xq.require }}</span>
          </div>
        </div>
        <div class="two_box">
          <div class="tab"><span>专业技术描述</span></div>
          <div class="title"><span>{{details.xq.job_describe}}</span></div>
        </div>

      </div>
      <div class="zhi_two">
        <div class="img_box">
          <img :src="details.xq.img|ImgFilter"/>
        </div>
        <div class="zhi_title" @click="conpany(details.xq.id)">{{ details.xq.company_name }}</div>
        <div class="content_text">
          <!-- {{details.list.description  }} -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { recruit_xqs, rens } from "../../common/js/api";
export default {
  name: "positiondetail",
  data() {
    return {
      details: {},
    };
  },
  created() {
    this.detail();
    this.ren_shu();
  },
    filters:{
        ImgFilter(value){
            return 'http://hout.kehui.cloud/' + value
        }
    },
  methods: {
    // 前往公司详细
    conpany(ids) {
      this.$router.push({
        path: "/Unitdetails",
         query:{
             id:ids
         }
      });
    },
    ren_shu() {
      rens({})
        .then((res) => {
          this.renss = res.data;
        })
        .catch((err) => {});
    },
    detail() {
      recruit_xqs({
        id: this.$route.query.id,
      })
        .then((res) => {
          console.log(res.data)
          this.details = res.data;

          var big = this.details;
          var big_num = this.details.p_number;
          for (var j = 0; j < this.renss.length; j++) {
            var samll_num = this.renss[j].id;
            if (big_num == samll_num) {
              Object.assign(big, { name_ren: this.renss[j].name });
            }
          }
          console.log(this.details);
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang="less">
  .el-divider--vertical{
    height: 21px;
  }
  .zhi_one{
    flex: 1.5;
  }
  .zhi_two{
    flex: 0.5;
  }
  /deep/.el-divider--horizontal{
    margin-left: 5px;
    margin-top: 60px;
    width: 98%;
    border-top: 1px dashed rgba(56, 146, 235, 100);

  }
  /deep/.el-divider{
    /*background-color: unset;*/
    padding-right: -30px;
  }
  /deep/.el-divider__text{
    color: rgba(56, 146, 235, 100);
    font-size: 20px;
  }
  /deep/.el-divider__text.is-left{
    left: 0px;
  }
.el-icon-school {
  font-size: 14px;
  color: #3892eb;
}
.el-icon-menu {
  font-size: 14px;
  color: #3892eb;
}
.con {
  background-color: #f8f9fa;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 135px;
  .top_box {
    width: 100%;
    height: 178px;
    line-height: 20px;
    color: rgba(16, 16, 16, 100);
    background-color: rgba(240, 248, 255, 100);
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 100);
    .flex_box {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      .button {
        width: 209px;
        height: 53px;
        line-height: 53px;
        border-radius: 4px;
        background-color: rgba(56, 146, 235, 100);
        color: rgba(255, 255, 255, 100);
        font-size: 20px;
        text-align: center;
        font-family: Microsoft Yahei;
        border: 1px solid rgba(255, 255, 255, 100);
      }
      .top_two_box {
        span {
          color: rgba(16, 16, 16, 100);
          font-size: 14px;
        }
        .yu_fen {
          margin-right: 8px;
          color: rgba(255, 0, 0, 100);
          font-size: 18px;
        }
      }
    }
    .title {
      color: rgba(16, 16, 16, 100);
      font-size: 18px;
      text-align: left;
      text-align: left;
    }
    .top_nei_box {
      width: 1200px;
      margin: 60px auto;
    }
  }
  .one_box {
    margin: 15px auto;
    width: 1200px;
    height: 637px;
    background-color: #fff;
    border: 1px solid rgba(255, 255, 255, 100);
    display: flex;
    justify-content: space-between;
    .zhi_two {
      width: 351px;
      height: 142px;
      margin: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .img_box {
        margin-top: 50px;
        width: 100px;
        height: 100px;
        img {
          width: 100px;
          height: 100px;
        }
      }
      .zhi_title {
        cursor: pointer;
        margin-top: 50px;
        width: 200px;
        color: rgba(16, 16, 16, 100);
        font-size: 18px;
      }
      .content_text {
        line-height: 30px;
        margin-top: 50px;
        width: 351px;
        color: rgba(105, 105, 105, 100);
        font-size: 12px;
      }
    }
  }
}
.two_box {
  margin: 20px;
  .title {
    margin-bottom: 30px;
    color: rgba(0, 5, 10, 100);
    font-size: 14px;
    text-align: left;
    line-height: 30px;
    font-family: SourceHanSansSC-regular;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    line-clamp: 10;
    -webkit-box-orient: vertical;
  }
  .tab {
    width: 200px;
    height: 38px;
    line-height: 38px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(56, 146, 235, 100);
    font-size: 18px;
    text-align: center;
    font-family: Microsoft Yahei;
    border: 1px solid rgba(56, 146, 235, 100);
  }
}
@media screen and (max-width: 1200px) {
	.con{
		margin-top: 15rem;
	}
	.w{
		width: 98%;
	}
	.con .top_box{
		height: auto;
	}
	.con .top_box .title{
		font-size: 2.2rem;
		line-height: 3rem;
		padding-bottom: 1rem;
	}
	.con .top_box .top_nei_box{
		width: 100%;
	}
	.con .top_box .flex_box .top_two_box span{
		font-size: 1.5rem;
	}
	.con .top_box .flex_box .top_two_box .yu_fen{
		font-size: 1.5rem;
	}
	.con .top_box .flex_box .button{
		width: 8rem;
		height: 3rem;
		font-size: 1.4rem;
	}
	/deep/.el-divider__text{
		font-size: 1.5rem;
	}
	.two_box .tab{
		width: 10rem;
		font-size: 1.4rem;
		height: 3rem;
		line-height: 3rem;
	}
	.two_box .title{
		font-size: 1.4rem;
		padding-top: 1rem;
	}
	.con .one_box{
		height: auto;
	}
}
</style>